const redirects = {
    'url1': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt6',
    'url2': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt6',
    'url3': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt6',
    'url4': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt6',
    'url5': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt6',

    'url6': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt14',
    'url7': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt14',
    'url8': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt14',
    'url9': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt14',
    'url10': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt14',

    'url11': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt22',
    'url12': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt22',
    'url13': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt22',
    'url14': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt22',
    'url15': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt22',

    'url16': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt29',
    'url17': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt29',
    'url18': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt29',
    'url19': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt29',
    'url20': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=okt29',

    'url21': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov05',
    'url22': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov05',
    'url23': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov05',
    'url24': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov05',
    'url25': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov05',

    'url26': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov12',
    'url27': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov12',
    'url28': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov12',
    'url29': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov12',
    'url30': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov12',

    'url31': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov19',
    'url32': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov19',
    'url33': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov19',
    'url34': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov19',
    'url35': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov19',

    'url36': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov26',
    'url37': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov26',
    'url38': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov26',
    'url39': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov26',
    'url40': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=nov26',

    'url41': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec3',
    'url42': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec3',
    'url43': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec3',
    'url44': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec3',
    'url45': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec3',

    'url46': 'https://charma.io/giftcard?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec10',
    'url47': 'https://charma.io/giftcard/classic?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec10',
    'url48': 'https://charma.io/giftcard/premium?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec10',
    'url49': 'https://charma.io/giftcard/deluxe?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec10',
    'url50': 'https://charma.io/giftcard/platinum?x-coupon=JULKAMPANJ&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=dec10',

    'url51': 'https://charma.io/?utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=apr29',
    'url52': 'https://charma.io/products/summer?utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=apr29',
    'url53': 'https://charma.io/giftcard?utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=apr29',

    'url54': 'https://charma.io/?x-coupon=sommar15edr&utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=maj27',
    'url55': 'https://charma.io/products/summer?x-coupon=sommar15edr&utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=maj27',
    'url56': 'https://charma.io/giftcard?x-coupon=sommar15edr&utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=maj27',

    'url57': 'https://charma.io/?x-coupon=sommar15edr&utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=juni3',
    'url58': 'https://charma.io/products/summer?x-coupon=sommar15edr&utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=juni3',
    'url59': 'https://charma.io/giftcard?x-coupon=sommar15edr&utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=juni3',

    'url60': 'https://charma.io/giftcard?x-coupon=sommar15edr&utm_source=reaktion&utm_medium=email&utm_campaign=summer21&utm_content=juni15',

    'url61': 'https://charma.io/giftcard?x-coupon=vinter15edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter15&utm_content=sep16',

    'url62': 'https://charma.io/giftcard?x-coupon=vinter15edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter15&utm_content=sep22',

    'url63': 'https://charma.io/giftcard?x-coupon=vinter15edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter15&utm_content=sep28',

    'url64': 'https://charma.io/giftcard?x-coupon=vinter15edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter15&utm_content=okt7',

    'url65': 'https://charma.io/giftcard?x-coupon=vinter15edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter15&utm_content=okt14',

    'url66': 'https://charma.io/giftcard?x-coupon=vinter15edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter15&utm_content=okt21',

    'url67': 'https://charma.io/giftcard?x-coupon=vinter15edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter15&utm_content=okt29',

    'url68': 'https://charma.io/giftcard?x-coupon=vinter10edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter10&utm_content=nov2',

    'url69': 'https://charma.io/giftcard?x-coupon=vinter10edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter10&utm_content=nov11',

    'url70': 'https://charma.io/giftcard?x-coupon=vinter10edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter10&utm_content=nov18',

    'url71': 'https://charma.io/giftcard?x-coupon=vinter10edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter10&utm_content=nov22',

    'url72': 'https://charma.io/giftcard?x-coupon=vinter10edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter10&utm_content=dec2',

    'url73': 'https://charma.io/giftcard?x-coupon=vinter10edr&utm_source=reaktion&utm_medium=email&utm_campaign=vinter10&utm_content=dec9',

    'url74': 'https://charma.io/giftcard?x-coupon=edr1&utm_source=reaktion&utm_medium=email&utm_campaign=edr1&utm_content=may12',
    'url75': 'https://charma.io/products/summer?x-coupon=edr1&utm_source=reaktion&utm_medium=email&utm_campaign=edr1&utm_content=may12',
    'url76': 'https://charma.io/?x-coupon=edr1&utm_source=reaktion&utm_medium=email&utm_campaign=edr1&utm_content=may12',

    'url77': 'https://charma.io/giftcard/?x-coupon=edr2&utm_source=reaktion&utm_medium=email&utm_campaign=edr2&utm_content=may12',
    'url78': 'https://charma.io/products/summer?x-coupon=edr2&utm_source=reaktion&utm_medium=email&utm_campaign=edr2&utm_content=may12',
    'url79': 'https://charma.io/?x-coupon=edr2&utm_source=reaktion&utm_medium=email&utm_campaign=edr2&utm_content=may12',

    'url80': 'https://charma.io/giftcard?x-coupon=edr1&utm_source=reaktion&utm_medium=email&utm_campaign=edr1&utm_content=may19',
    'url81': 'https://charma.io/products/summer?x-coupon=edr1&utm_source=reaktion&utm_medium=email&utm_campaign=edr1&utm_content=may19',
    'url82': 'https://charma.io/?x-coupon=edr1&utm_source=reaktion&utm_medium=email&utm_campaign=edr1&utm_content=may19',

    'url83': 'https://charma.io/giftcard/?x-coupon=edr2&utm_source=reaktion&utm_medium=email&utm_campaign=edr2&utm_content=may19',
    'url84': 'https://charma.io/products/summer?x-coupon=edr2&utm_source=reaktion&utm_medium=email&utm_campaign=edr2&utm_content=may19',
    'url85': 'https://charma.io/?x-coupon=edr2&utm_source=reaktion&utm_medium=email&utm_campaign=edr2&utm_content=may19',

    'url86': 'https://charma.io/giftcard?x-coupon=edr1&utm_source=reaktion&utm_medium=email&utm_campaign=edr1&utm_content=may12',
    'url87': 'https://charma.io/giftcard?x-coupon=edr2&utm_source=reaktion&utm_medium=email&utm_campaign=edr2&utm_content=may12',

    'url88': 'https://charma.io/giftcard?x-coupon=edr1&utm_source=reaktion&utm_medium=email&utm_campaign=edr1&utm_content=june13',
    'url89': 'https://charma.io/giftcard?x-coupon=edr2&utm_source=reaktion&utm_medium=email&utm_campaign=edr2&utm_content=june13',

    'url90': 'https://charma.io/p/julkampanj-2022?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=okt04',
    'url91': 'https://charma.io/satisfaction?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=okt04',
    'url93': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=okt04',
    'url94': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=okt04',

    'url95': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=okt13',
    'url96': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=okt13',

    'url97': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=okt20',
    'url98': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=okt20',
    'url99': 'https://charma.io/p/julkampanj-2022?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=okt20',
    'url100': 'https://charma.io/satisfaction?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=okt20',

    'url101': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=okt25',
    'url102': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=okt25',
    'url103': 'https://charma.io/p/julkampanj-2022?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=okt25',
    'url104': 'https://charma.io/satisfaction?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=okt25',

    'url105': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov03',
    'url106': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov03',
    'url107': 'https://charma.io/p/julkampanj-2022?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=nov03',
    'url108': 'https://charma.io/satisfaction?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=nov03',

    'url109': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov09',
    'url110': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov09',
    'url111': 'https://charma.io/p/julkampanj-2022?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=nov09',
    'url112': 'https://charma.io/satisfaction?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=nov09',

    'url113': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov17',
    'url114': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov17',
    'url115': 'https://charma.io/p/julkampanj-2022?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=nov17',
    'url116': 'https://charma.io/satisfaction?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=nov17',

    'url117': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov23',
    'url118': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov23',
    'url119': 'https://charma.io/p/julkampanj-2022?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=nov23',
    'url120': 'https://charma.io/satisfaction?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionDiscount&utm_content=nov23',

    'url121': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov28',
    'url122': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=nov28',

    'url123': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=dec08',
    'url124': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=dec08',

    'url125': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=dec14',
    'url126': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=dec14',

    'url127': 'https://charma.io/p/julkampanj-sf-anytime?x-coupon=22reaktionSF&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=dec19',
    'url128': 'https://charma.io/satisfaction?x-coupon=22reaktion10&utm_source=reaktion&utm_medium=email&utm_campaign=reaktionSFAnytime&utm_content=dec19',

    'url129': 'https://charma.io/gift/sommarkampanj-2023?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=apr27',
    'url130': 'https://charma.io/product/C-7455611/solstol-svart-oeko-tex?catid=113&pgid=506?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=apr27',
    'url131': 'https://charma.io/product/CH30080203/lean-vattenflaska?catid=97&pgid=506?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=apr27',
    'url132': 'https://charma.io/product/C-39553429/strandtennis-set?catid=77&pgid=506?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=apr27',
    'url133': 'https://charma.io/product/C-0158390/alba-picknickplad-grs-atervunnen-polyester?catid=112&pgid=506?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=apr27',
    'url134': 'https://charma.io/product/C-39553496/odlingssats-for-tomat?catid=113&pgid=506?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=apr27',
    'url135': 'https://charma.io/product/C-7458410/hamamhandduk-randig-recycled?catid=113&pgid=506?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=apr27',
    'url136': 'https://charma.io/p/giftcard-summer?utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=apr27',

    'url137': 'https://charma.io/gift/sommarkampanj-2023?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj4',
    'url138': 'https://charma.io/product/C-7455611/solstol-svart-oeko-tex?catid=113&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj4',
    'url139': 'https://charma.io/product/CH30080203/lean-vattenflaska?catid=97&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj4',
    'url140': 'https://charma.io/product/C-39553429/strandtennis-set?catid=77&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj4',
    'url141': 'https://charma.io/product/C-0158390/alba-picknickplad-grs-atervunnen-polyester?catid=112&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj4',
    'url142': 'https://charma.io/product/C-39553496/odlingssats-for-tomat?catid=113&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj4',
    'url143': 'https://charma.io/product/C-7458410/hamamhandduk-randig-recycled?catid=113&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj4',
    'url144': 'https://charma.io/p/giftcard-summer?utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj4',

    'url145': 'https://charma.io/gift/sommarkampanj-2023?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url146': 'https://charma.io/p/giftcard-summer?utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url147': 'https://charma.io/product/CH1690104/bounce-strandtennis?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url148': 'https://charma.io/product/CH30180203/lean-vattenflaska?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url149': 'https://charma.io/product/CH26382703/kylvaska-vit?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url150': 'https://charma.io/product/CH32110203/yatzy-coffee-table-bla?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url151': 'https://charma.io/product/CH29342703/picknickplad-120x150cm?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url152': 'https://charma.io/product/C-39553467/picknickuppsattning-for-2-personer?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url153': 'https://charma.io/product/C-25827815/softybag-original?catid=113&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url154': 'https://charma.io/product/C-0442945/tradlos-hogtalare-gjord-av-vete?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',
    'url155': 'https://charma.io/product/C-39559050/hamamhandduk-75x150-cm?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=maj29',

    'url156': 'https://charma.io/gift/sommarkampanj-2023?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url157': 'https://charma.io/p/giftcard-summer?utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url158': 'https://charma.io/product/CH1690104/bounce-strandtennis?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url159': 'https://charma.io/product/CH30180203/lean-vattenflaska?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url160': 'https://charma.io/product/CH26382703/kylvaska-vit?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url161': 'https://charma.io/product/CH32110203/yatzy-coffee-table-bla?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url162': 'https://charma.io/product/CH29342703/picknickplad-120x150cm?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url163': 'https://charma.io/product/C-39553467/picknickuppsattning-for-2-personer?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url164': 'https://charma.io/product/C-25827815/softybag-original?catid=113&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url165': 'https://charma.io/product/C-0442945/tradlos-hogtalare-gjord-av-vete?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',
    'url166': 'https://charma.io/product/C-39559050/hamamhandduk-75x150-cm?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni01',

    'url167': 'https://charma.io/gift/sommarkampanj-2023?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url168': 'https://charma.io/p/giftcard-summer?utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url169': 'https://charma.io/product/CH1690104/bounce-strandtennis?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url170': 'https://charma.io/product/CH30180203/lean-vattenflaska?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url171': 'https://charma.io/product/CH26382703/kylvaska-vit?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url172': 'https://charma.io/product/CH32110203/yatzy-coffee-table-bla?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url173': 'https://charma.io/product/CH29342703/picknickplad-120x150cm?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url174': 'https://charma.io/product/C-39553467/picknickuppsattning-for-2-personer?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url175': 'https://charma.io/product/C-25827815/softybag-original?catid=113&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url176': 'https://charma.io/product/C-0442945/tradlos-hogtalare-gjord-av-vete?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',
    'url177': 'https://charma.io/product/C-39559050/hamamhandduk-75x150-cm?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni08',

    'url178': 'https://charma.io/gift/sommarkampanj-2023?x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url179': 'https://charma.io/p/giftcard-summer?utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url180': 'https://charma.io/product/CH1690104/bounce-strandtennis?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url181': 'https://charma.io/product/CH30180203/lean-vattenflaska?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url182': 'https://charma.io/product/CH26382703/kylvaska-vit?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url183': 'https://charma.io/product/CH32110203/yatzy-coffee-table-bla?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url184': 'https://charma.io/product/CH29342703/picknickplad-120x150cm?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url185': 'https://charma.io/product/C-39553467/picknickuppsattning-for-2-personer?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url186': 'https://charma.io/product/C-25827815/softybag-original?catid=113&pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url187': 'https://charma.io/product/C-0442945/tradlos-hogtalare-gjord-av-vete?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',
    'url188': 'https://charma.io/product/C-39559050/hamamhandduk-75x150-cm?pgid=506&x-coupon=ice-cream-2023&utm_source=reaktion&utm_medium=email&utm_campaign=summercampaign-2023&utm_content=juni16',

    'url189': {
        "landing_page": 'https://charma.io/giftcard/exclusive?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Exclusive"
    },
    'url190': {
        "landing_page": 'https://charma.io/giftcard?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard"
    },
    'url191': {
        "landing_page": 'https://charma.io/giftcard/lux?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Lux"
    },
    'url192': {
        "landing_page": 'https://charma.io/giftcard/black?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Black"
    },
    'url193': {
        "landing_page": 'https://charma.io/giftcard/black+?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Black+"
    },
    'url194': {
        "landing_page": 'https://charma.io/giftcard/platinum?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Platinum"
    },
    'url195': {
        "landing_page": 'https://charma.io/giftcard/platinum+?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Platinum+"
    },
    'url196': {
        "landing_page": 'https://charma.io/p/blackweek?utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Black Week"
    },
    'url197': {
        "landing_page": 'https://charma.io/giftcard/lux?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Lux"
    },
    'url198': {
        "landing_page": 'https://charma.io/giftcard/black?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Black"
    },
    'url199': {
        "landing_page": 'https://charma.io/giftcard/exclusive?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Exclusive"
    },
    'url200': {
        "landing_page": 'https://charma.io/giftcard/black+?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Black+"
    },
    'url201': {
        "landing_page": 'https://charma.io/giftcard/platinum?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Platinum"
    },
    'url202': {
        "landing_page": 'https://charma.io/giftcard/platinum+?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Platinum+"
    },
    'url203': {
        "landing_page": 'https://charma.io/giftcard?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard"
    },
    'url204': {
        "landing_page": 'https://charma.io/giftcard/lux?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Lux+"
    },
    'url205': {
        "landing_page": 'https://charma.io/giftcard/black?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Black"
    },
    'url206': {
        "landing_page": 'https://charma.io/giftcard/exclusive?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Exclusive"
    },
    'url207': {
        "landing_page": 'https://charma.io/giftcard/black+?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Black+"
    },
    'url208': {
        "landing_page": 'https://charma.io/giftcard/platinum?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Platinum"
    },
    'url209': {
        "landing_page": 'https://charma.io/giftcard/platinum+?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Giftcard Platinum+"
    },
    'url210': {
        "landing_page": 'https://charma.io/p/ny-lagstiftning-flergangsalternativ-take-away?utm_source=reaktion&utm_medium=email&utm_campaign=lagstiftning&utm_content=cta',
        "name": "Flergångsalternativ take away"
    },
    'url211': {
        "landing_page": 'https://se.trustpilot.com/review/charma.io',
        "name": "Trustpilot"
    },
    'url212': {
        "landing_page": 'https://charma.io/satisfaction+?x-coupon=23reaktion&utm_source=reaktion&utm_medium=email&utm_campaign=julkampanj&utm_content=cta',
        "name": "Nöjdhetsgaranti"
    },


    

    'url213': {
        "landing_page": "https://charma.io/gift/easter?x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - Toppbild"
    },

    'url214': {
        "landing_page": "https://charma.io/gift/easter?x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - CTA Till påskgavår"
    },

    'url215': {
        "landing_page": "https://charma.io/product/C-5675842/klassiskt-paskagg-380-g?pgid=190&x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - Bild påskägg"
    },

    'url216': {
        "landing_page": "https://charma.io/product/C-5675842/klassiskt-paskagg-380-g?pgid=190&x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - CTA påskägg"
    },

    'url217': {
        "landing_page": "https://charma.io/product/C-5675837/paskagg-i-guldplat-250-g?pgid=190&x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - Bild - påskägg i guldplåt"
    },

    'url218': {
        "landing_page": "https://charma.io/product/C-5675837/paskagg-i-guldplat-250-g?pgid=190&x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - CTA - påskägg i guldplåt"
    },

    'url219': {
        "landing_page": "https://charma.io/product/C-5675843/paskaggskorg-med-40-st-fyllda-sma-paskagg?pgid=190&x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - Bild - Päskäggskorg"
    },

    'url220': {
        "landing_page": "https://charma.io/product/C-5675843/paskaggskorg-med-40-st-fyllda-sma-paskagg?pgid=190&x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - CTA - Päskäggskorg"
    },

    'url221': {
        "landing_page": "https://charma.io/product/C-5672845/vit-godiskorg-2400g?pgid=190&x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - Bild - Godiskorg"
    },

    'url222': {
        "landing_page": "https://charma.io/product/C-5672845/vit-godiskorg-2400g?pgid=190&x-coupon=24reaktion2&utm_source=reaktion",
        "name": "Påsk 2024 - CTA - Godiskorg"
    },

    'url223': {
        "landing_page" : "https://charma.io/gift/easter?x-coupon=24reaktion2&utm_source=reaktion",
        'name': 'Påsk 2024 - Kontakta oss'
    },

    'url224': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion2&utm_source=reaktion",
        "name" : "Påsk 2024 - Hemsida"
    },

    'url225': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=header",
        "name" : "Sommar 2024 - Toppbild"
    },

    

    'url226': {
        "landing_page" : "https://charma.io/category/cooling-bags?pgid=101&x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta2",
        "name" : "Sommar 2024 - Bild-Kylväska Bild"
    },

    'url227': {
        "landing_page" : "https://charma.io/category/cooling-bags?pgid=101&x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta2",
        "name" : "Sommar 2024 - Till Kylväskor"
    },

    'url228': {
        "landing_page" : "https://charma.io/product/C-25827815/softybag-original?pgid=101&catid=113&x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta3",
        "name" : "Sommar 2024 - Bild-Softybag Bild"
    },

    'url229': {
        "landing_page" : "https://charma.io/product/C-25827815/softybag-original?pgid=101&catid=113&x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta3",
        "name" : "Sommar 2024 - Till-Softybag"
    },


    'url230': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Sommar 2024 - Bild-Gåvokort"
    },

    'url231': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Sommar 2024 - Till-Gåvokort"
    },

    'url232': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta5",
        "name" : "Sommar 2024 - Bild-er-idé"
    },

    'url233': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta5",
        "name" : "Sommar 2024 - Visa alla produkter"
    },

    'url234': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild1",
        "name" : "Sommar 2024 - Bild alla produkter"
    },

    'url235': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta6",
        "name" : "Sommar 2024 - Visa sortiment"
    },

    'url236': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta7",
        "name" : "Sommar 2024 - Kontakta oss"
    },

    'url237': {
        "landing_page" : "https://charma.io?xcoupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=logo",
        "name" : "Sommar 2024 - Charma logo"
    },

    'url238': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta1",
        "name" : "Sommar 2024 - Charma logo"
    },


    'url239': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=header",
        "name" : "Sommar - edr2 - Toppbild"
    },

    'url240': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta1",
        "name" : "Sommar - edr2 - CTA1"
    },

    'url241': {
        "landing_page" : "https://charma.io/category/cooling-bags?pgid=101&x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta2",
        "name" : "Sommar - edr2 - Kylväskor - Bild"
    },

    'url242': {
        "landing_page" : "https://charma.io/category/cooling-bags?pgid=101&x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta2",
        "name" : "Sommar - edr2 - Kylväskor - CTA2" 
    },

    'url243': {
        "landing_page" : "https://charma.io/product/C-25827815/softybag-original?pgid=101&catid=113&x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta3",
        "name" : "Sommar - edr2 - Softybag - Bild" 
    },

    'url244': {
        "landing_page" : "https://charma.io/product/C-25827815/softybag-original?pgid=101&catid=113&x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta3",
        "name" : "Sommar - edr2 - Softybag - CTA3" 
    },


    'url245': {
        "landing_page" : "https://charma.io/giftcard/exclusive?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Sommar - edr2 - Gåvokort - Bild" 
    },

    'url246': {
        "landing_page" : "https://charma.io/giftcard/exclusive?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Sommar - edr2 - Gåvokort - CTA4" 
    },

    'url247': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta5",
        "name" : "Sommar - edr2 - Något helt annat - Bild" 
    },

    'url248': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta5",
        "name" : "Sommar - edr2 - Något helt annat - CTA5" 
    },

    'url249': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild1",
        "name" : "Sommar - edr2 - Bild-alla-produkter" 
    },

    'url250': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta6",
        "name" : "Sommar - edr2 - Kontakta oss - CTA6" 
    },

    'url251': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta7",
        "name" : "Sommar - edr2 - Kontakta oss - CTA7" 
    },

    'url252': {
        "landing_page" : "https://charma.io?xcoupon=rea24&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=logo",
        "name" : "Sommar - edr2 - Charma logo - CTA8" 
    },



    'url253': {
        "landing_page" : "https://charma.io/category/deli-summer?pgid=101&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=header",
        "name" : "Sommar2024 - Toppbild" 
    },

    'url254': {
        "landing_page" : "https://charma.io/category/deli-summer?pgid=101&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta1",
        "name" : "Sommar2024 - Till erbjudande - CTA1" 
    },

    'url255': {
        "landing_page" : "https://charma.io/product/C-47979711/bbq-gypsy-smoke-spice-rub-box?pgid=101&catid=634&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=produkt1",
        "name" : "Sommar2024 - Spice Rub Box - Bild" 
    },


    'url256': {
        "landing_page" : "https://charma.io/product/C-47979711/bbq-gypsy-smoke-spice-rub-box?pgid=101&catid=634&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=produkt1",
        "name" : "Sommar2024 - Spice Rub Box - CTA" 
    },


    'url257': {
        "landing_page" : "https://charma.io/product/C-47981485/presentlada-gourmet-deli?pgid=101&catid=634&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=produkt2",
        "name" : "Sommar2024 - Presentlåda - Bild" 
    },

    
    'url258': {
        "landing_page" : "https://charma.io/product/C-47981485/presentlada-gourmet-deli?pgid=101&catid=634&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=produkt2",
        "name" : "Sommar2024 - Presentlåda - CTA" 
    },

    'url259': {
        "landing_page" : "https://charma.io/product/C-47981538/bbq-grillvaska?pgid=101&catid=634&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=produkt3",
        "name" : "Sommar2024 - Grillväska - Bild" 
    },


    'url260': {
        "landing_page" : "https://charma.io/product/C-47981538/bbq-grillvaska?pgid=101&catid=634&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=produkt3",
        "name" : "Sommar2024 - Grillväska - CTA" 
    },

    'url261': {
        "landing_page" : "https://charma.io/product/C-49476344/goodiebag-till-sommarfesten?pgid=101&catid=634&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=produkt4",
        "name" : "Sommar2024 - Grillväska - Bild" 
    },

    'url262': {
        "landing_page" : "https://charma.io/product/C-49476344/goodiebag-till-sommarfesten?pgid=101&catid=634&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=produkt4",
        "name" : "Sommar2024 - Grillväska - CTA" 
    },

    'url263': {
        "landing_page" : "https://charma.io/category/deli-summer?pgid=101&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta2",
        "name" : "Sommar2024 - Till alla delikatesser" 
    },

    'url264': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta3",
        "name" : "Sommar2024 - Gåvokort" 
    },

    'url265': {
        "landing_page" : "https://charma.io/category/cooling-bags?pgid=101&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Sommar2024 - Kylväskor" 
    },

    'url266': {
        "landing_page" : "https://charma.io/category/blankets-picnic?pgid=217&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Sommar2024 - Picknickplädar" 
    },

    'url267': {
        "landing_page" : "https://charma.io/category/towels?pgid=101&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta6",
        "name" : "Sommar2024 - Handdukar" 
    },

    'url268': {
        "landing_page" : "https://charma.io/category/bottles?pgid=101&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta7",
        "name" : "Sommar2024 - Vattenflaskor" 
    },

    'url269': {
        "landing_page" : "https://charma.io/category/thermos?pgid=101&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta8",
        "name" : "Sommar2024 - Termosmuggar" 
    },

    'url270': {
        "landing_page" : "https://charma.io/category/summer-games?pgid=101&x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta9",
        "name" : "Sommar2024 - Spel" 
    },


    'url271': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta10",
        "name" : "Sommar2024 - Sommargåvor" 
    },

    'url272': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta11",
        "name" : "Sommar2024 - Gåvor med fokus" 
    },

    'url273': {
        "landing_page" : "https://charma.io/p/satisfaction?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta12",
        "name" : "Sommar2024 - Fullständig trygghet" 
    },

    'url274': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta13",
        "name" : "Sommar2024 - Personlig service" 
    },

    'url275': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta13",
        "name" : "Sommar2024 - Kundnöjdhet" 
    },

    'url276': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta13",
        "name" : "Sommar2024 - Gåvoexperter" 
    },

    'url277': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta15",
        "name" : "Sommar2024 - Kontakta oss" 
    },

    'url278': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion3&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta16",
        "name" : "Sommar2024 - Charma" 
    },
    

    
    'url279': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=logo",
        "name" : "Sommar2024 - Logo - EDR4" 
    },

    'url280': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=header",
        "name" : "Sommar2024 - Toppbild - EDR4" 
    },

    'url281': {
        "landing_page" : "https://charma.io/category/gavor-under-50-kr?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta1",
        "name" : "Sommar2024 - Gåvor under 50 kr - EDR4" 
    },

    'url282': {
        "landing_page" : "https://charma.io/category/51-100-kr?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta2",
        "name" : "Sommar2024 - Gåvor 51-100 kr - EDR4" 
    },
    
    'url283': {
        "landing_page" : "https://charma.io/category/101-200-kr?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta3",
        "name" : "Sommar2024 - Gåvor 101-200 kr - EDR4" 
    },

    'url284': {
        "landing_page" : "https://charma.io/category/201-300-kr?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Sommar2024 - Gåvor 201-300 kr - EDR4" 
    },

    'url285': {
        "landing_page" : "https://charma.io/category/301-400-kr?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta5",
        "name" : "Sommar2024 - Gåvor 301-400 kr - EDR4" 
    },

    'url286': {
        "landing_page" : "https://charma.io/category/401-500-kr?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta6",
        "name" : "Sommar2024 - Gåvor 401-500 kr - EDR4" 
    },

    'url287': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta7",
        "name" : "Sommar2024 - Alla sommargåvor - EDR4" 
    },

    'url288': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild1",
        "name" : "Sommar2024 - Solstol - EDR4" 
    },

    'url289': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta8",
        "name" : "Sommar2024 - Alla sommargåvor - EDR4" 
    },

    'url290': {
        "landing_page" : "https://charma.io/p/satisfaction?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta9",
        "name" : "Sommar2024 - Läs mer - EDR4" 
    },

    'url291': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta10",
        "name" : "Sommar2024 - Gåvokort - EDR4" 
    },

    'url292': {
        "landing_page" : "https://charma.io/category/cooling-bags?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta11",
        "name" : "Sommar2024 - Kylväskor - EDR4" 
    },

    'url293': {
        "landing_page" : "https://charma.io/category/blankets-picnic?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta12",
        "name" : "Sommar2024 - Picknickplädar - EDR4" 
    },

    'url294': {
        "landing_page" : "https://charma.io/category/towels?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta13",
        "name" : "Sommar2024 - Handdukar - EDR4" 
    },

    'url295': {
        "landing_page" : "https://charma.io/category/bottles?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta14",
        "name" : "Sommar2024 - Vattenflaskor - EDR4" 
    },


    'url296': {
        "landing_page" : "https://charma.io/category/thermos?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta15",
        "name" : "Sommar2024 - Termosmuggar - EDR4" 
    },

    'url297': {
        "landing_page" : "https://charma.io/category/summer-games?pgid=101&x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta16",
        "name" : "Sommar2024 - Spel - EDR4" 
    },

    'url298': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta17",
        "name" : "Sommar2024 - Sommargåvor - EDR4" 
    },

    'url299': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta18",
        "name" : "Sommar2024 - Gåvoexperter - EDR4" 
    },

    'url300': {
        "landing_page" : "https://charma.io/gift/summer?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=footer",
        "name" : "Sommar2024 - Kontakta oss - EDR4" 
    },

    'url301': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion4&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=logo",
        "name" : "Sommar2024 - Charma - EDR4" 
    },


    'url302' : {
        "landing_page" : "https://charma.io/gift/express?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=header",
        "name" : "Toppbild - EDR5"
    },

    'url303' : {
        "landing_page" : "https://charma.io/gift/express?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta1",
        "name" : "Supersnabba gåvor - EDR5"
    },


    'url304' : {
        "landing_page" : "https://charma.io/product/C-40881522/glacial-vattenflaska&pgid=600&catid=159&x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta2",
        "name" : "Vattenflaska - EDR5"
    },


    'url305' : {
        "landing_page" : "https://charma.io/product/C-39564352/kylvaska-for-6st-33cl-burkar&pgid=600&catid=3&x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta3",
        "name" : "Kylväska - EDR5"
    },

    'url306' : {
        "landing_page" : "https://charma.io/product/C-39553430/strandstol-i-tra&pgid=600&catid=113&x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Strandstol - EDR5"
    },

    'url307' : {
        "landing_page" : "https://charma.io/product/C-0469214/fashion-bluetooth-r-hogtalare-i-tyg&pgid=600&catid=1&x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta5",
        "name" : "Högtalare - EDR5"
    },

    'url308' : {
        "landing_page" : "https://charma.io/product/C-0445534/duffelbag-av-atervunnen-bomull&pgid=600&catid=160&x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta6",
        "name" : "Duffelbag - EDR5"
    },

    'url309' : {
        "landing_page" : "https://charma.io/product/C-39564820/ryggsack-med-rullstangning&pgid=600&catid=265&x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta7",
        "name" : "Ryggsäck - EDR5"
    },
    
    'url310' : {
        "landing_page" : "https://charma.io/gift/express?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta8",
        "name" : "Visa alla - EDR5"
    },

    'url311' : {
        "landing_page" : "https://charma.io/giftcard/black?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild1",
        "name" : "Tips - EDR5"
    },

    'url312' : {
        "landing_page" : "https://charma.io/giftcard/black?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta9",
        "name" : "Visa alla gavokort - EDR5"
    },

    'url313' : {
        "landing_page" : "https://charma.io?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta10",
        "name" : "Gåvor med fokus på kvalitet - EDR5"
    },

    'url314' : {
        "landing_page" : "https://charma.io/p/satisfaction?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta11",
        "name" : "Nöjdhetsgaranti - EDR5"
    },

    'url315' : {
        "landing_page" : "https://charma.io?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta12",
        "name" : "Personlig service - EDR5"
    },

    'url316' : {
        "landing_page" : "https://charma.io?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta13",
        "name" : "Branschens högsta - EDR5"
    },

    'url317' : {
        "landing_page" : "https://charma.io?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta15",
        "name" : "Branschens högsta - EDR5"
    },

    'url318' : {
        "landing_page" : "https://charma.io?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta15",
        "name" : "Kontakta oss - EDR5"
    },

    'url319' : {
        "landing_page" : "https://charma.io?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta16",
        "name" : "Kontakta oss - EDR5"
    },

  //edr6

  'url320': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild1",
        "name" : "Sommar2024 - edr6 - Toppbild"
    },
    
    'url321': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta1",
        "name" : "Sommar2024 - edr6 - Till erbjudande"
    },

    'url322': {
        "landing_page" : "https://charma.io/giftcard/black?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild2",
        "name" : "Sommar2024 - edr6 - Gåvokort black"
    },

    'url323': {
        "landing_page" : "https://charma.io/giftcard/black?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta2",
        "name" : "Sommar2024 - edr6 - Visa gåvor"
    },

    'url324': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild3",
        "name" : "Sommar2024 - edr6 - Sortiment"
    },

    'url325': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta3",
        "name" : "Sommar2024 - edr6 - Till gåvokorten"
    },

    'url326': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta4",
        "name" : "Sommar2024 - edr6 - Till gåvokorten"
    },

    'url327': {
        "landing_page" : "https://charma.io/giftcard/lux?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta5",
        "name" : "Sommar2024 - edr6 - Lux"
    },
    
    'url328': {
        "landing_page" : "https://charma.io/giftcard/black?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta6",
        "name" : "Sommar2024 - edr6 - Black"
    },

    'url329': {
        "landing_page" : "https://charma.io/giftcard/exclusive?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta7",
        "name" : "Sommar2024 - edr6 - Exclusive"
    },

    'url330': {
        "landing_page" : "https://charma.io/giftcard/black+?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta8",
        "name" : "Sommar2024 - edr6 - Black plus"
    },

    'url331': {
        "landing_page" : "https://charma.io/giftcard/platinum?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta9",
        "name" : "Sommar2024 - edr6 - Platinum"
    },

    'url332': {
        "landing_page" : "https://charma.io/giftcard/platinum+?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta10",
        "name" : "Sommar2024 - edr6 - Platinum plus"
    },

    'url333': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild4",
        "name" : "Sommar2024 - edr6 - Ett personligare gåvokort"
    },

    'url334': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta11",
        "name" : "Sommar2024 - edr6 - Till gåvokorten"
    },

    'url335': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=bild5",
        "name" : "Sommar2024 - edr6 - Gåvoexperter"
    },

    'url336': {
        "landing_page" : "https://charma.io/giftcard?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta12",
        "name" : "Sommar2024 - edr6 - Kontakta oss"
    },

    'url337': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta13",
        "name" : "Sommar2024 - edr6 - Gåvor med fokus på kvalitet"
    },

    'url338': {
        "landing_page" : "https://charma.io/p/satisfaction?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta14",
        "name" : "Sommar2024 - edr6 - Nöjdhetsgaranti"
    },

    'url339': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta15",
        "name" : "Sommar2024 - edr6 - Personlig service"
    },

    'url340': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta16",
        "name" : "Sommar2024 - edr6 - Branschens högsta"
    },
    
    'url341': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta17",
        "name" : "Sommar2024 - edr6 - Charma"
    },

    'url342': {
        "landing_page" : "https://charma.io/giftcard/black?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta6",
        "name" : "Sommar2024 - edr6 - Black"
    },

    'url343': {
        "landing_page" : "https://charma.io/giftcard/exclusive?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta7",
        "name" : "Sommar2024 - edr6 - Exclusive"
    },

    'url344': {
        "landing_page" : "https://charma.io/giftcard/black+?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta8",
        "name" : "Sommar2024 - edr6 - Black plus"
    },

    'url345': {
        "landing_page" : "https://charma.io/giftcard/platinum?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta9",
        "name" : "Sommar2024 - edr6 - Platinum"
    },


    'url346': {
        "landing_page" : "https://charma.io/giftcard/platinum+?x-coupon=24reaktion5&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta10",
        "name" : "Sommar2024 - edr6 - Platinum"
    },

    'url347': {
        "landing_page" : "https://charma.io?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta16",
        "name" : "Sommar2024 - edr6 - Branschens högsta"
    },

    'url348': {
        "landing_page" : "https://charma.io/giftcard/platinum+?x-coupon=24reaktion6&utm_source=reaktion&utm_medium=email&utm_campaign=sommar2024&utm_content=cta10",
        "name" : "Sommar2024 - edr6 - Platinum plus"
    },

    'url349': {
        "landing_page" : "https://charma.io/p/charma-kivra?x-coupon=digital20&utm_source=reaktion&utm_medium=email&utm_campaign=kivra2024&utm_content=",
        "name" : "Jul2024 - edr2 - kivra 20%"
    },

    'url350': {
        "landing_page" : "https://se.trustpilot.com/review/charma.io",
        "name" : "Jul2024 - edr2 - trustpilot"
    },

    'url351': {
        "landing_page" : "https://charma.io/p/bonusgava?x-coupon=bonus01&utm_source=reaktion&utm_medium=email&utm_campaign=bonus01",
        "name" : "Jul2024 - edr3 - bonusgåva"
    },

    'url352': {
        "landing_page" : "https://charma.io/p/bonusgava?x-coupon=bonus01&utm_source=reaktion&utm_medium=email&utm_campaign=bonus01",
        "name" : "Jul2024 - edr4 - bonusgåva"
    },
    



    'url353': {
        "landing_page" : "https://charma.io/product/CA32298556/brandfilt-med-tryckt-sleeve?pgid=117&x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Toppbild"
    },

    'url354': {
        "landing_page" : "https://charma.io/product/CA42994825/fotogenlampa-guld?pgid=117&x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Fotogenlampa, Solstickan"
    },

    'url355': {
        "landing_page" : "https://charma.io/product/CA54796957/plad-i-lammull-fjalltopp?pgid=117&x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Pläd, Klippan"
    },

    'url356': {
        "landing_page" : "https://charma.io/product/C-49781610/gjutjarnsgryta-1-8-liter?pgid=117&x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Gjutjärnsgryta, Satake"
    },

    'url357': {
        "landing_page" : "https://charma.io/product/CA42994855/varmeljuslada-tandsticksror-doftljus-i-presentask?pgid=117&x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Presentlåda, Solstickan"
    },

    'url358': {
        "landing_page" : "https://charma.io/product/CA12896854/the-ritual-of-karma-small-gift-set?pgid=117&x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Gåvoset, Rituals"
    },

    'url359': {
        "landing_page" : "https://charma.io/product/CA49794787/knivset-3-knivar-brooklyn-krom?pgid=117&x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Knivset, Taylor's Eye Witness"
    },

    'url360': {
        "landing_page" : "https://charma.io/gift/christmas?x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Visa alla julklappar"
    },

    'url361': {
        "landing_page" : "https://charma.io/?x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Coupon"
    },

    'url362': {
        "landing_page" : "https://charma.io/p/satisfaction?x-coupon=rea01&utm_source=reaktion&utm_medium=email",
        "name" : "Jul2024 - edr5 - Nöjdhetsgaranti"
    },

    'url363': { "landing_page" : "https://charma.io/p/gratis-gavofrakt?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr6 - Gratis gåvofrakt" },
    'url364': { "landing_page" : "https://charma.io/product/CG53794601/gavokort-440-kr?pgid=871&x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr6 - Gåvokort 440kr" },
    'url365': { "landing_page" : "https://charma.io?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr6 - Landningssida" },
    'url366': { "landing_page" : "https://charma.io/giftcard?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr6 - Gågokorten" },
    'url367': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr6 - Nöjdhetsgaranti" },

    'url368': { "landing_page" : "https://charma.io/p/gratis-gavofrakt?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr7 - Gratis gåvofrakt" },
    'url369': { "landing_page" : "https://charma.io/product/CG53794601/gavokort-440-kr?pgid=871&x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr7 - Gåvokort 440kr" },
    'url370': { "landing_page" : "https://charma.io?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr7 - Gåvokorten" },
    'url371': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr7 - Nöjdhetsgaranti" },

    'url372': { "landing_page" : "https://charma.io/p/gratis-gavofrakt?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr8 - Gratis gåvofrakt" },
    'url373': { "landing_page" : "https://charma.io/product/CG53794601/gavokort-440-kr?pgid=871&x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr8 - Gåvokort 440kr" },
    'url374': { "landing_page" : "https://charma.io/giftcard?x-coupon=unicef&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr8 - Unicef" },
    'url375': { "landing_page" : "https://charma.io?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr8 - Gåvokorten" },
    'url376': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=rea02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr8 - Nöjdhetsgaranti" },
    
    'url377': { "landing_page" : "https://charma.io?x-coupon=rea03&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr9 - Landningssida" },
    'url378': { "landing_page" : "https://charma.io/p/deli-konfekt?x-coupon=godjul15&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr9 - Deli konfekt" },
    'url379': { "landing_page" : "https://charma.io/product/CA49495063/julpasen?catid=26&x-coupon=godjul15&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr9 - Julpåsen" },
    'url381': { "landing_page" : "https://charma.io/product/CA5698603/julsack-med-rosett-1-kg?catid=528&x-coupon=godjul15&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr9 - Julsäck" },
    'url382': { "landing_page" : "https://charma.io/product/CA47995027/italiensk-gourmet?catid=26&x-coupon=godjul15&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr9 - Italienks gourmet" },
    'url383': { "landing_page" : "https://charma.io/product/C-5672849/godiskorg-7000g?catid=528&x-coupon=godjul15&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr9 - Godiskorg" },
    'url384': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=rea03&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr9 - Nöjdhetsgaranti" },
    
    'url385': { "landing_page" : "https://charma.io/p/black-week-24?x-coupon=blackweek24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr10 - Black week 1" },
    'url386': { "landing_page" : "https://charma.io?x-coupon=blackweek24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr10 - Black week 1" },
    'url387': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=blackweek24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr10 - Nöjdhetsgaranti" },

    'url388': { "landing_page" : "https://charma.io/p/black-week-24?x-coupon=blackweek24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr11 - Black week 2" },
    'url389': { "landing_page" : "https://charma.io?x-coupon=blackweek24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr11 - Black week 2" },
    'url390': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=blackweek24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr11 - Nöjdhetsgaranti" },

    'url391': { "landing_page" : "https://charma.io/p/black-friday-24?x-coupon=blackfriday24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr12 - Black week 3" },
    'url392': { "landing_page" : "https://charma.io?x-coupon=blackfriday24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr12 - Black week 3" },
    'url393': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=blackfriday24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr12 - Nöjdhetsgaranti" },

    'url394': { "landing_page" : "https://charma.io/p/black-friday-24?x-coupon=blackfriday24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr13 - Black week 4 - version a" },
    'url395': { "landing_page" : "https://charma.io?x-coupon=blackfriday24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr13 - Black week 4 - version a" },
    'url396': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=blackfriday24&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr13 - Nöjdhetsgaranti - version a" },

    'url397': { "landing_page" : "https://charma.io/p/black-friday-24?x-coupon=blackfriday2024&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr13 - Black week 4 - version b" },
    'url398': { "landing_page" : "https://charma.io?x-coupon=blackfriday2024&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr13 - Black week 4 - version b" },
    'url399': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=blackfriday2024&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr13 - Nöjdhetsgaranti - version b" },

    'url400': { "landing_page" : "https://charma.io/p/valbar-bonusgava?x-coupon=bonus02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr14 - valbar bonusgåva - chefer" },
    'url401': { "landing_page" : "https://charma.io/giftcard?x-coupon=bonus02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr14 - gåvokort - chefer" },
    'url402': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=bonus02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr14 - Nöjdhetsgaranti - chefer" },
    'url403': { "landing_page" : "https://charma.io?x-coupon=bonus02&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr14 - chefer" },

    'url404': { "landing_page" : "https://charma.io/p/valbar-bonusgava?x-coupon=bonus03&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr14 - valbar bonusgåva - anställda" },
    'url405': { "landing_page" : "https://charma.io/giftcard?x-coupon=bonus03&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr14 - gåvokort - anställda" },
    'url406': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=bonus03&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr14 - Nöjdhetsgaranti - anställda" },
    'url407': { "landing_page" : "https://charma.io?x-coupon=bonus03&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr14 - anställda" },

    'url408': { "landing_page" : "https://charma.io/p/giftcard-digital?x-coupon=digital10&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr15 - gåvokort" },
    'url409': { "landing_page" : "https://charma.io/p/satisfaction?x-coupon=digital10&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr15 - Nöjdhetsgaranti" },
    'url410': { "landing_page" : "https://charma.io?x-coupon=digital10&utm_source=reaktion&utm_medium=email", "name" : "Jul2024 - edr15" },
    
};  


export default redirects